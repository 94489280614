.app__navbar{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0,0,0, 0.8);
    backdrop-filter: blur(4px);
    
    
    position: fixed;
    z-index: 2;
}

.app__navbar-logo{
    display: flex;
    
    justify-content: flex-start;
    align-items: center;
    padding : 0.7rem;
  
    
    
    img{
        width: 150px;
        height: 50px;

        @media screen and (min-width: 2000px){
            width: 180px;
            height: 60px;
        }    
    }
}

.app__navbar-links{
    float: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    

    li{
        margin: 0 1.5rem;
        cursor:pointer;
        flex-direction: column;
        

        div{
            width:10px;
            height: 10px;
            background:transparent;
            border-radius: 50%;

            margin-bottom: 5px;
            
        }

        a{
          font-size: .9rem;
          padding: 0.5rem;  
          color: #fff;
            text-decoration: none;
            flex-direction:column;
            text-transform:uppercase;
            letter-spacing: 0.1em;
            transition: all 0.3s ease-in-out;
            &:hover{

              align-items: center;
  
            
              padding: 0.5rem;
              border-radius: 10px;
              cursor: pointer;
              background-color: var(--secondary-color);
              box-shadow: 0 0 25px #5c8daf;
              transition: all 0.3s ease-in-out;
              color: #fff;
            }

        }

    }
    @media screen and (max-width: 900px){
        display: none;
        
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    opacity: 0.9;
  
  
    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;

  
      
  
      width: 50%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      
      background: url('../../assets/bgWhite.png');
      background: rgba(0,0,0, 0.9);
      backdrop-filter: blur(4px);
    
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 90%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a{
            
            color: #fff;
            text-decoration: none;
            flex-direction:column;
            text-transform:uppercase;
            padding: 0.5rem;
            font-weight:bold;
            font-size: .9rem;
            letter-spacing: 0.2em;
            transition: all 0.3s ease-in-out;
            &:hover{

              align-items: center;
  
            
        
              border-radius: 10px;
              cursor: pointer;
              background-color: var(--secondary-color);
              box-shadow: 0 0 25px #5c8daf;
              transition: all 0.3s ease-in-out;
              color: #fff;
            }

        }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }