#projects{
  
  background: linear-gradient(to top, rgb(9, 32, 63) 0%, rgb(83, 120, 149) 100%);
}
.app__projects {
  margin-top: 30px;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__project-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  
    margin: 4rem 0 2rem;
  
    .app__project-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: rgba(255, 255, 255, 0.3);
      
      color: #000;
      font-weight: 800;
  
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }
  
  .app__project-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    .app__project-item {
      width: 270px;
      flex-direction: column;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      margin: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background: linear-gradient(178.6deg, rgb(20, 36, 50) 11.8%, rgb(124, 143, 161) 83.8%);   background-size: cover;

      color: #000;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        .app__project-hover{
          opacity: 1;
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }
  
      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
      }
    }
  }
  
  .app__project-img {
    width: 100%;
    height: 230px;
  
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      height: 350px;
    }
  }
  
  .app__project-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 1);
      color: #fff;
  
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }
  
  .app__project-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
      color: var(--secondary-color);
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  
    .app__project-tag {
      position: absolute;
  
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background: linear-gradient(to top, rgb(9, 32, 63) 0%, rgb(83, 120, 149) 100%);
      top: -25px;
    }
  }
  .using{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
   
    .using-item {
      padding: 0.3rem 0.3rem;
      border-radius: 10px;
      background: linear-gradient(to top, rgb(9, 32, 63) 0%, rgb(83, 120, 149) 100%);
      
      cursor: pointer;
      margin: 0.1rem;
  
    }
  }
